
import { reactive } from '@vue/composition-api'

export const commonStore = reactive({
  
  injectCallBack:null,
  setInjectCallBack(fn){
    this.injectCallBack = fn
  },
  storeId:'',
  setStoreId(data){
    console.log('设置customerId',data)
    this.customerId =data
    console.log('injectCallBack类型',typeof(this.injectCallBack))
    if(this.injectCallBack && typeof(this.injectCallBack)  === 'function'){
      this.injectCallBack()
    }
  }
})